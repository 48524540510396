<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Autorizar pagos" :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="info" icon="mdi-refresh" @click="refresh()" :loading="loading" />
        <Basic-Btn text="Aprobar" color="success" icon="mdi-check" :disabled="!selected.length" @click="view(true)" :loading="loading" />
        <Basic-Btn text="Rechazar" color="error" icon="mdi-close" :disabled="!selected.length" @click="view(false)" :loading="loading" />
        <span class="text-h7 font-weight-bold ml-3">Pagos seleccionados: {{ selected.length }}</span>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" show-select :single-select="false"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id"
      class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.zona`]="{ item }">
          {{ item.zona ? item.zona.nombre : "Sin asignar" }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.update" :title="(actionMode ? 'Aprobar' : 'Rechazar') +  ' pagos pendientes'">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn :text="actionMode ? 'Aprobar' : 'Rechazar'" color="success" icon="mdi-check" @click="actionMode ? approve() : reject()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-textarea v-model="forms.update.comentarios" label="Comentarios*" prepend-icon="mdi-message-text-outline" :rules="rules.max2000" :disabled="loading" outlined dense required></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, } from "@/components";

export default {
  name: "pending-payments",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "ID de solicitud", align: "left", sortable: true, value: "solicitud_prestamo" },
      { text: "Grupo", align: "left", sortable: true, value: "grupo" },
      { text: "Cliente", align: "left", sortable: true, value: "cliente" },
      { text: "Monto aprobado", align: "left", sortable: true, value: "monto_aprobado" },
      { text: "Semana", align: "left", sortable: true, value: "semana" },
      { text: "Usuario creador de pago", align: "left", sortable: true, value: "usuario_creacion_pago" },
      { text: "Fecha de pago", align: "left", sortable: true, value: "fecha_pago" },
      { text: "Monto de pago", align: "left", sortable: true, value: "monto_pago" },
      { text: "Método de pago", align: "left", sortable: true, value: "metodo_pago" },
      { text: "Referencia de Autorización", align: "left", sortable: true, value: "referencia_deposito" },
      { text: "Estatus de pago", align: "left", sortable: true, value: "estatus_pago" }
    ],
    items: [],
    selected: [],
    deleted: null,
    actionMode: false,
    forms: {
      update: {
        comentarios: "",
      }
    },
    rules: {
      max2000: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 2000) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      update: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.paymentDetail + '/pending')
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.update) {
        this.$refs.formUpdate.reset();
        this.forms.update.comentarios = ''
        this.dialogs.update = false
      }
    },
    view(bool) {
      this.actionMode = bool
      this.dialogs.update = true
    },
    async approve() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let ok = true
        for (let i = 0; i < this.selected.length; i++) {
          let data = JSON.parse(JSON.stringify(this.forms.update))
          data.id = this.selected[i].id
          try {
            await this.axios.put(services.routes.paymentDetail + '/approve', { data })
          }
          catch(error) {
            ok = false
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = '(' + data.id + ')  ' + error.response.data.message || "Servicio no disponible";
          }
          if (!ok) {
            break;
          }
        }
        if (ok) {
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Pagos actualizados exitosamente";
        }
        this.selected = []
        this.loading = false
        this.cancel();
        this.refresh();
      }
      else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    async reject() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let ok = true
        for (let i = 0; i < this.selected.length; i++) {
          let data = JSON.parse(JSON.stringify(this.forms.update))
          data.id = this.selected[i].id
          try {
            await this.axios.post(services.routes.paymentDetail + '/reject', { data })
          }
          catch(error) {
            ok = false
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = '(' + data.id + ')  ' + error.response.data.message || "Servicio no disponible";
          }
          if (!ok) {
            break;
          }
        }
        if (ok) {
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Pagos rechazados exitosamente";
        }
        this.selected = []
        this.loading = false
        this.cancel();
        this.refresh();
      }
      else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
  },
};
</script>

<style scoped></style>
